import { Track, LongList, Matchup, getDB, shuffleArray, officialList } from './core';
import LonglistLogic from './longlist';

export type MatchupResult = {
  left: Track['id']
  right: Track['id']
  resolution: 'left' | 'right' | 'skip' | 'reject-left' | 'reject-right'
}

export type WeightedScores = {
  wins: number,
  winWeight: number,
  losses: number,
  lossWeight: number
  }

export default class MatchupLogic {

  private longlistLogic = new LonglistLogic();

  history: MatchupResult[] = [];

  async shortlistCount(): Promise<number> {
    const db = await getDB();

    const shortlistCount = await db.countFromIndex('longlist', 'state', 'accept');

    console.debug('shortlistCount', shortlistCount);

    return shortlistCount;
  }

  async getCurrent(): Promise<Matchup | undefined> {
    const db = await getDB();

    const remaining = await db.count('matchups');
    if (remaining === 0) {
      await this.startRound();
    }

    // Skip anything that's already been resolved
    const txn = await db.transaction(['matchups', 'matchupResults'], 'readwrite');
    const matchups = txn.objectStore('matchups');
    const results = txn.objectStore('matchupResults');

    let cursor = await matchups.openCursor(IDBKeyRange.lowerBound(-Infinity, false));

    while (cursor) {
      const resolvedL = await results.count([cursor.value.left, cursor.value.right]);
      const resolvedR = await results.count([cursor.value.right, cursor.value.left]);
      if ((cursor.value.left !== cursor.value.right) && (resolvedL === 0) && (resolvedR === 0)) {
        return cursor.value;
      } else {
        await cursor.delete();
      }
      cursor = await cursor.continue();
    }
  }

  async startRound() {
    const derangedTracks = await this.getDerangedTracks();

    const db = await getDB();
    const matchups = await db.transaction('matchups', 'readwrite').store;

    for (var i = 0; i < derangedTracks.length; i++) {
      const [leftSide, rightSide] = derangedTracks[i];
      await matchups.put({
        order: i,
        left: leftSide,
        right: rightSide
      });
    }
  }

  async getDerangedTracks(): Promise<[number, number][]> {
    // Get our shortlist
    const shortlist = await this.longlistLogic.getShortlist();

    const db = await getDB();
    const results = await db.transaction('matchupResults', 'readwrite').store;

    const shortlistIds = new Set(shortlist.map(t => t.id));

    // Remove ALL matchup results that are no longer in our shortlist
    let cursor = await results.openCursor();
    while (cursor) {
      if (!(shortlistIds.has(cursor.value.winner) && shortlistIds.has(cursor.value.loser))) {
        await cursor.delete();
      }
      cursor = await cursor.continue();
    }

    // Get all the track scores
    const winners = results.index('winner');
    const losers = results.index('loser');

    const segments = new Map<number, number[]>();
    for(let track of shortlist) {
      const score = await winners.count(track.id) - await losers.count(track.id);
      const tracksWithScore = segments.get(score);
      if (tracksWithScore) {
        tracksWithScore.push(track.id);
      } else {
        segments.set(score, [track.id]);
      }
    }

    const matchups: [number, number][] = [];

    for(let [score, tracks] of segments) {
      if (tracks.length === 1) {
        // Lone track, match up against a random other track from the shortlist
        let leftSide = tracks[0];
        let rightSide: number | undefined;
        while(!rightSide || (rightSide !== leftSide)) {
          rightSide = shortlist[Math.floor(Math.random() * shortlist.length)].id;
        }
        matchups.push([leftSide, rightSide]);
      } else {
        // Create a derangement
        shuffleArray(tracks);
        for(let i = 0; i < tracks.length; i++) {
          const leftSide = tracks[i];
          let rightSide = tracks[(i + 1) % tracks.length];

          const resultCount = (await results.count([leftSide, rightSide]) +
            await results.count([rightSide, leftSide]));
          if (resultCount) {
            // This has been resolved already, match up lefty with a random other track
            // This might have been resolved too - but if so, it'll be skipped in getCurrent
            let rightSide: number | undefined;
            while(!rightSide || (rightSide !== leftSide)) {
              rightSide = shortlist[Math.floor(Math.random() * shortlist.length)].id;
            }
          }

          matchups.push([leftSide, rightSide]);
        }
      }
    }

    shuffleArray(matchups);

    return matchups;
  }

  async resolve(result: MatchupResult) {
    const {left, right} = result;
    if (result.resolution === 'left') {
      await this.decide(left, right)
    } else if (result.resolution === 'right') {
      await this.decide(right, left)
    } else if (result.resolution === 'reject-left') {
      await this.reject(left);
    } else if (result.resolution === 'reject-right') {
      await this.reject(right);
    } else {
      await this.skip();
    }

    const db = await getDB();
    const matchups = db.transaction('matchups', 'readwrite').store;
    const matchupKeyL = await matchups.index('key').getKey([left, right]);
    const matchupKeyR = await matchups.index('key').getKey([right, left]);
    if (matchupKeyL != null) {
      await matchups.delete(matchupKeyL);
    }
    if (matchupKeyR != null) {
      await matchups.delete(matchupKeyR);
    }

    this.history.push(result);
  }

  async decide(winner: Track['id'], loser: Track['id']) {
    const db = await getDB();
    const results =  db.transaction('matchupResults', 'readwrite').store
    // Create or update result object

    await results.delete([loser, winner]);
    results.put({
      winner: winner,
      loser: loser
    });
  }

  async reject(track: Track['id']) {
    this.longlistLogic.updateState(officialList[track], 'reject');

    const db = await getDB();
    const matchups = db.transaction('matchups', 'readwrite').store;

    // Also remove any other matchups containing this
    const matchupKeyL = await matchups.index('left').getKey(track);
    const matchupKeyR = await matchups.index('right').getKey(track);
    if (matchupKeyL != null) {
      await matchups.delete(matchupKeyL);
    }
    if (matchupKeyR != null) {
      await matchups.delete(matchupKeyR);
    }

    await matchups.transaction.done;
  }

  async skip() {
    // Do nothing!
  }

  async getScore(track: Track['id']) {
    const db = await getDB();
    const results = await db.transaction('matchupResults', 'readonly').store;
    const winners = results.index('winner');
    const losers = results.index('loser');

    return await winners.count(track) - await losers.count(track);
  }

  async getWeightedScore(track: Track['id']): Promise<WeightedScores> {
    const db = await getDB();
    const results = await db.transaction('matchupResults', 'readonly').store;
    const winners = results.index('winner');
    const losers = results.index('loser');

    const wonAgainst = (await winners.getAll(track)).map(t => t.loser);
    const lostAgainst = (await losers.getAll(track)).map(t => t.winner);

    // Winning against high scoring tracks counts for more
    let winningWeight = 0;
    for(let t of wonAgainst) {
      winningWeight += Math.exp(await winners.count(t) - await losers.count(t));
    }

    // Losing against low scoring tracks counts for more
    let losingWeight = 0;
    for(let t of lostAgainst) {
      losingWeight += Math.exp(await losers.count(t) - await winners.count(t));
    }

    return {
      wins: wonAgainst.length,
      winWeight: winningWeight,
      losses: lostAgainst.length,
      lossWeight: losingWeight
    }
  }

  async getTop10(threshold: number): Promise<[Track, WeightedScores][]> {
    const shortlist = await this.longlistLogic.getShortlist();
    const scores: Record<number, WeightedScores> = {};
    for(let track of shortlist) {
      const weighted = await this.getWeightedScore(track.id);
      scores[track.id] = weighted;
    }

    return shortlist.map(t => [t, scores[t.id]] as [Track, WeightedScores])
      .filter(([track, weights]) => (weights.winWeight - weights.lossWeight) > threshold)
      .sort(([aTrack, aWeights], [bTrack, bWeights]) => {
        let aWeight = aWeights.winWeight - aWeights.lossWeight;
        let bWeight = bWeights.winWeight - bWeights.lossWeight;
        return bWeight - aWeight;
      });
  }

  async removeTracksNotInShortlist() {
    const shortlist = await this.longlistLogic.getShortlist();

    const db = await getDB();
    const txn = await db.transaction(['matchups', 'matchupResults'], 'readwrite');
    const matchups = txn.objectStore('matchups');
    const results = txn.objectStore('matchupResults');

    const shortlistIds = new Set(shortlist.map(t => t.id));

    let matchupsCursor = await matchups.openCursor();
    while (matchupsCursor) {
      if (!(shortlistIds.has(matchupsCursor.value.left) && shortlistIds.has(matchupsCursor.value.right))) {
        await matchupsCursor.delete();
      }
      matchupsCursor = await matchupsCursor.continue();
    }

    let resultsCursor = await results.openCursor();
    while (resultsCursor) {
      if (!(shortlistIds.has(resultsCursor.value.winner) && shortlistIds.has(resultsCursor.value.loser))) {
        await resultsCursor.delete();
      }
      resultsCursor = await resultsCursor.continue();
    }
  }

  async undo(result: MatchupResult) {
    const db = await getDB();
    const txn = await db.transaction(['matchups', 'matchupResults'], 'readwrite');
    const matchups = txn.objectStore('matchups');
    const results = txn.objectStore('matchupResults');

    const minMatchup = await matchups.index('order').getKey(IDBKeyRange.lowerBound(-Infinity));
    const matchup: Matchup = {
      left: result.left,
      right: result.right,
      order: (minMatchup ?? 0) - 1
    };

    // This isn't great - it should abort the transaction if the dependent task fails - but there shouldn't be a situation where that happens
    const i = this.history.indexOf(result);
    if (i !== -1) {
      this.history.splice(i, 1);
    }

    await matchups.put(matchup);

    if (result.resolution === 'left') {
      await results.delete([result.left, result.right]);
    } else if (result.resolution === 'right') {
      await results.delete([result.right, result.left]);
    } else if (result.resolution === 'reject-left') {
      // This doesn't undo the side effects of deleting the track, but they're not crucial
      const track = officialList[result.left];
      await this.longlistLogic.updateState(track, 'accept');
    } else if (result.resolution === 'reject-right') {
      const track = officialList[result.right];
      await this.longlistLogic.updateState(track, 'accept');
    }
  }

}
