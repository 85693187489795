import _officialList from './2023.json';
import _spotifyArt from './2023_art.json';

import * as idb from 'idb';

export const officialList = _officialList as Record<string, Track>;
export const spotifyArt = _spotifyArt as Record<string, Record<string, string> | null>;
export const PREVIEW_URL = new URL("https://abcmedia.akamaized.net/triplej/hottest100/23/samples/");

export type Artist = {
  "id": number,
  "title": string,
  "spotify_artist_id": string | null,
  "created_at": string,
  "updated_at": string,
  "pivot": {
    "track_id": number,
    "artist_id": number,
    "order": number
  }
}

export type Track = {
  "id": number,
  "title": string,
  "artist_name": string,
  "track_preview": string | null,
  "spotify_track_id": string | null,
  "apple_track_id": string | null,
  "is_official": 0 | 1,
  "created_at": string,
  "updated_at": string,
  "full_title": string,
  "artists": Artist[]
}

export type LongList = {
  id: number,
  order: number,
  state: "unknown" | "accept" | "reject" | "reject-all"
}

export type Matchup = {
  left: Track['id'],
  right: Track['id'],
  order: number
}

export type MatchupResult = {
  winner: Track['id'],
  loser: Track['id']
}

export interface H100DB extends idb.DBSchema {
  longlist: {
    value: LongList
    key: number
    indexes: {
      order: number,
      state: LongList['state']
    }
  }

  matchups: {
    value: Matchup,
    key: number,
    indexes: {
      order: number,
      left: Track['id'],
      right: Track['id'],
      key: [Track['id'], Track['id']]
    }
  }

  matchupResults: {
    value: MatchupResult,
    key: [Track['id'], Track['id']],
    indexes: {
      winner: Track['id'],
      loser: Track['id']
    }
  }
}

export function shuffleArray(array: Array<any>) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export async function getDB(): Promise<idb.IDBPDatabase<H100DB>> {
  const db = await idb.openDB<H100DB>('h100_2023', 6, {
    upgrade(db, oldVersion, newVersion, transaction) {
      oldVersion = oldVersion ?? 0;

      if (oldVersion < 1) {
        console.log('upgrading to version 1');

        const longlist = db.createObjectStore('longlist', {keyPath: 'id'});

        // Each track can only appear once
        longlist.createIndex('order', 'order', {unique: true});
        longlist.createIndex('state', 'state');
      }

      if (oldVersion < 2) {
        console.log('upgrading to version 2');

        const matchups = db.createObjectStore('matchups', {keyPath: 'order'});
        matchups.createIndex('order', 'order', {unique: true});

        const matchupResults = db.createObjectStore('matchupResults', {keyPath: ['winner', 'loser']});
        matchupResults.createIndex('winner', 'winner');
        matchupResults.createIndex('loser', 'loser');
      }

      if (oldVersion < 3) {
        console.log('upgrading to version 3');

        const matchups = transaction.objectStore('matchups');
        matchups.createIndex('key', ['winner', 'loser']);
      }

      if (oldVersion < 4) {
        console.log('upgrading to version 4');

        const matchups = transaction.objectStore('matchups');
        matchups.deleteIndex('key');
        matchups.createIndex('key', ['left', 'right']);
      }

      if (oldVersion < 6) {
        console.log('upgrading to version 6');

        const matchups = transaction.objectStore('matchups');
        matchups.createIndex('left', 'left');
        matchups.createIndex('right', 'right');
      }
    }
  });

  return db;
}

export function previewURL(track: Track): string | undefined {
  if (track.track_preview) {
    return new URL(track.track_preview, PREVIEW_URL).toString();
  }
}

export function spotifyURL(track: Track): string | undefined {
  if (track.spotify_track_id) {
    return `spotify:track:${track.spotify_track_id}`;
  }
}
