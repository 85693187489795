import React from 'react';
import ReactDOM from 'react-dom';
import * as Router from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Fulllist from './Fulllist';
import Longlist from './Longlist';
import Shortlist from './Shortlist';
import Matchup from './Matchup';
import SpotifyRedirect from './SpotifyRedirect';

const INDEX = <main className="text-center lead">
<p><big><strong>Hottest 100 votes are now closed for 2023. You can view the <Router.NavLink to="/fulllist">official voting list here</Router.NavLink>.</strong></big></p>
<p>Hottest 100 Helper helps you plan your Hottest 100 votes.</p>
<p>If you've already got your shortlist sorted, start by copy/pasting your shortlist from the voting page or a Spotify playlist link, then click <Router.Link to="/matchup">Pick your top 10</Router.Link>.</p>
<p>If you want to listen to the whole longlist and pick your shortlist from that, click <Router.Link to="/longlist">Create your shortlist</Router.Link>.</p>
</main>

const PRIVACY_POLICY = <main className="text-center lead">
<h2>Privacy Policy</h2>
<p>All of your data is entirely stored on your computer. I don't collect anything about your shortlist, matchups or Spotify account. The only data that gets sent back to my server is totally anonymous <a href="https://web.dev/vitals/">web vitals</a>. These don't contain anything that can identify you or your computer; they're basically just the time it took for your browser to render the page.</p>
<h3>How do you remember where I was up to?</h3>
<p>Your data is stored in your browser. If you clear website data associated with hottest100.gormster.me, everything will be wiped out. If you want to keep a copy of your data, or switch browsers or devices, you can use the Copy button to get a text version of your progress; then you can paste it into a text file (to save a backup) or hit the Paste button in another browser.</p>
<h3>What do you do with the web vitals?</h3>
<p>Mostly? Nothing. If I start getting reports that Hottest 100 Helper is running super slowly, they're a good place to start to try and figure out what's going wrong. But for the most part, they get auto-deleted after a little while without anyone ever looking at them.</p>
<h3>Who are you?</h3>
<p>G'day, my name's <a href="https://gormster.me">Morgan</a>.</p>
</main>
;


ReactDOM.render(
  <React.StrictMode>
    <Router.BrowserRouter>
       <Router.Routes>
        <Router.Route path="/" element={<App />}>
          <Router.Route path="fulllist" element={<Fulllist />} />
          <Router.Route path="longlist" element={<Longlist />} />
          <Router.Route path="shortlist" element={<Shortlist />} />
          <Router.Route path="matchup" element={<Matchup />} />
          <Router.Route path="spotify_redirect" element={<SpotifyRedirect />} />
          <Router.Route path="privacy-policy" element={PRIVACY_POLICY} />
          <Router.Route index
        element={INDEX} />
        </Router.Route>
        <Router.Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Router.Routes>
    </Router.BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

function sendToAnalytics(metric: any) {
  const body = JSON.stringify(metric);
  const url = 'https://hottest100.gormster.me/analytics';

  // Use `navigator.sendBeacon()` if available, falling back to `fetch()`
  if (navigator.sendBeacon) {
    navigator.sendBeacon(url, body);
  } else {
    fetch(url, { body, method: 'POST', keepalive: true });
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV === 'production') {
  reportWebVitals(sendToAnalytics);
}

