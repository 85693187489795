import React from 'react';
import './SwipeToDelete.scss';

export interface SwipeToDeleteProps {
  onDelete?: () => void;
  deleteText: string;
  children: React.ReactNode;
}

export interface SwipeToDeleteState {
  contentOffset: number;
  isDragging: boolean;
}

const MIN_DRAG_DISTANCE = 5;
const MIN_SWIPE_DISTANCE = 120;

export default class SwipeToDelete extends React.Component<SwipeToDeleteProps, SwipeToDeleteState> {

  private firstTouch?: {pointerId: number, clientX: number, element: HTMLElement};

  pointerDownOutside: () => void;

  constructor(props: SwipeToDeleteProps) {
    super(props);
    this.state = {
      contentOffset: 0,
      isDragging: false
    }

    this.pointerDownOutside = () => {
      this._cancelDrag();
    };
  }

  componentDidMount() {
    // Listen for pointer down outside
    document.body.addEventListener('pointerdown', this.pointerDownOutside);
  }

  componentWillUnmount() {
    document.body.removeEventListener('pointerdown', this.pointerDownOutside);
  }

  onPointerDown(event: React.PointerEvent<HTMLDivElement>) {

    this.firstTouch = {
      pointerId: event.nativeEvent.pointerId,
      clientX: event.nativeEvent.clientX + this.state.contentOffset,
      element: event.currentTarget
    };

    if (this.state.contentOffset > MIN_DRAG_DISTANCE) {
      this.setState({
        isDragging: true
      });
    }

    event.currentTarget.setPointerCapture(event.pointerId);

    event.stopPropagation();
    event.preventDefault();
  }

  onPointerMove(event: React.PointerEvent<HTMLDivElement>) {
    if (!this.firstTouch) {
      return;
    }

    const contentOffset = Math.max(this.firstTouch.clientX - event.nativeEvent.clientX, 0);

    if (contentOffset < MIN_DRAG_DISTANCE) {
      return;
    }

    if (event.nativeEvent.pointerId === this.firstTouch.pointerId) {
      this.setState({
        isDragging: true,
        contentOffset
      });
    }

    event.stopPropagation();
    event.preventDefault();
  }

  onPointerUp(event: React.PointerEvent<HTMLDivElement>) {
    if (!this.firstTouch) {
      return;
    }

    if (!this.state.isDragging) {
      this._cancelDrag();
      return;
    }

    const newState = {
      isDragging: false,
      contentOffset: Math.max(this.firstTouch.clientX - event.nativeEvent.clientX, 0)
    };

    if (newState.contentOffset > MIN_SWIPE_DISTANCE) {
      newState.contentOffset = MIN_SWIPE_DISTANCE;
    } else {
      newState.contentOffset = 0
    }

    this.firstTouch.element.releasePointerCapture(event.pointerId);

    delete this.firstTouch;

    this.setState(newState);

    event.stopPropagation();
    event.preventDefault();
  }

  onPointerCancel(event: React.PointerEvent<HTMLDivElement>) {
    this._cancelDrag();
  }

  _cancelDrag() {
     if (this.firstTouch) {
      this.firstTouch.element.releasePointerCapture(this.firstTouch.pointerId);
      delete this.firstTouch;
    }

    this.setState({
      isDragging: false,
      contentOffset: 0
    });
  }

  render() {

    const contentStyle: React.CSSProperties = {
      marginRight: (this.state.contentOffset) + 'px',
      marginLeft: (-this.state.contentOffset) + 'px',
    }

    if (this.state.isDragging) {
      contentStyle.transition = 'none';
    }

    return <div className="SwipeToDelete"
      onPointerDown={(evt) => this.onPointerDown(evt)}
      onPointerMove={(evt) => this.onPointerMove(evt)}
      onPointerUp={(evt) => this.onPointerUp(evt)}
      onPointerCancel={(evt) => this.onPointerCancel(evt)}
      >
        <div className="SwipeToDelete-delete">
          <button type="button" onClick={this.props.onDelete}>
            {this.props.deleteText}
          </button>
        </div>
        <div className="SwipeToDelete-content" style={contentStyle}>
          {this.props.children}
        </div>
      </div>
  }

}
