import React from 'react';
import './TrackDisplay.scss';
import AudioPlayer from './logic/audioplayer';
import SwipeToDelete from './SwipeToDelete';

const PLAY_BUTTON      = <img src={process.env.PUBLIC_URL + "img/play-circle-fill.svg"} alt="Play" title="Play" />
const PAUSE_BUTTON     = <img src={process.env.PUBLIC_URL + "img/pause-circle-fill.svg"} alt="Pause" title="Pause" />
const SPOTIFY_BUTTON   = <img src={process.env.PUBLIC_URL + "img/spotify-logo.svg"} alt="Open in Spotify" title="Open in Spotify" />

interface TrackDisplayProps {
  albumArt?: string;
  artist: string;
  track: string;
  playbackUrl?: string;
  spotifyUrl?: string;
  onDelete?: () => void;
  autoplay?: boolean
}

interface TrackDisplayState {
  isPlaying: boolean;
}

export default class TrackDisplay extends React.Component<TrackDisplayProps, TrackDisplayState> {

  private _audio = AudioPlayer.shared;

  private _ref: React.RefObject<HTMLDivElement>;

  constructor(props: TrackDisplayProps) {
    super(props);
    this.state = {
      isPlaying: false
    }

    this.audioEndedHandler = this.audioEndedHandler.bind(this);

    this._ref = React.createRef();
  }

  componentDidMount() {
    this._audio.addEventListener('ended', this.audioEndedHandler);
  }

  componentWillUnmount() {
    if (this.state.isPlaying) {
      this._audio.pause();
    }
    this._audio.removeEventListener('ended', this.audioEndedHandler);
  }

  componentDidUpdate(prevProps: TrackDisplayProps) {
    if ((prevProps.playbackUrl !== this.props.playbackUrl) &&
      this.state.isPlaying) {
      if (this.props.autoplay) {
        this._audio.play(this.props.playbackUrl ?? '');
      } else {
        this.setState({
          isPlaying: false
        });
      }
    }
  }

  audioEndedHandler(evt: Event) {
    if ((evt instanceof CustomEvent) && (evt.detail.src === this.props.playbackUrl)) {
      this.setState({
        isPlaying: false
      });
    }
  }

  blur() {
    this._ref?.current?.blur();
  }

  render() {
    let audioButton: React.ReactNode = '';
    if (this.props.playbackUrl) {
      const buttonArt = this.state.isPlaying ? PAUSE_BUTTON : PLAY_BUTTON;
      audioButton = <div className="TrackDisplay-external-player" onPointerUp={(evt) => this._playPauseClick(evt)}>{buttonArt}</div>;
    }

    let spotifyButton: React.ReactNode = '';
    if (this.props.spotifyUrl) {
      spotifyButton = <a className="TrackDisplay-external-player" href={this.props.spotifyUrl} target="_blank" rel="noreferrer" onPointerUp={(evt) => evt.stopPropagation()}>
        {SPOTIFY_BUTTON}
      </a>;
    }

    let albumArt: React.ReactNode = '';
    if (this.props.albumArt) {
      albumArt = <img src={this.props.albumArt} alt="" />;
    }

    const container = <div className="TrackDisplay-container">
            <div className="TrackDisplay-picture">
              {albumArt}
            </div>
            <div className="TrackDisplay-details">
              <div className="TrackDisplay-artist">{this.props.artist}</div>
              <div className="TrackDisplay-track">{this.props.track}</div>
            </div>
            <div className="TrackDisplay-links" onPointerDown={(evt) => evt.stopPropagation()}>
              {audioButton}
              {spotifyButton}
            </div>
          </div>

    if (this.props.onDelete) {
      let deleteButton = <div className="TrackDisplay-delete-button" aria-label="Delete" onPointerUp={(evt) => this._deleteClick(evt)}></div>
      return (
        <div className="TrackDisplay-wrapper" ref={this._ref}>
          <SwipeToDelete onDelete={this.props.onDelete} deleteText="Remove from shortlist">
            {container}
          </SwipeToDelete>
          {deleteButton}
        </div>
      );
    } else {
      return (<div className="TrackDisplay-wrapper" ref={this._ref}>
        {container}
      </div>);
    }

  }

  _playPauseClick(evt: React.SyntheticEvent<any, PointerEvent>) {
    evt.stopPropagation();
    let isPlaying = this.state.isPlaying;

    if (isPlaying) {
      this._audio.pause();
      isPlaying = false;
    } else if (this.props.playbackUrl) {
      this._audio.play(this.props.playbackUrl);
      isPlaying = true;
    }

    this.setState({ isPlaying });
  }

  _deleteClick(evt: React.SyntheticEvent<any, PointerEvent>) {
    evt.stopPropagation();
    this.props.onDelete?.();
  }

}


