import React from 'react';
import './Shortlist.scss';
import TrackDisplay from './TrackDisplay';
import { Track, spotifyArt, officialList, spotifyURL, previewURL } from './logic/core';

interface FulllistProps {}

interface FulllistState {
  list: Track[]
}

export default class FulllistComponent extends React.Component<FulllistProps, FulllistState> {

  constructor(props: FulllistProps) {
    super(props);
    let list: Track[] = Object.values(officialList);
    list.sort((a, b) => {
      if (a.artist_name < b.artist_name) {
        return -1;
      } else if (a.artist_name > b.artist_name) {
        return 1;
      }
      return 0;
    });

    this.state = {
      list
    };
  }

  renderShortlistPreview() {
    return this.state.list.map((track) => {
      return <div key={track.id}><TrackDisplay
        track={track.title}
        artist={track.artist_name}
        albumArt={spotifyArt[track.id]?.["300"]}
        playbackUrl={previewURL(track)}
        spotifyUrl={spotifyURL(track)}
        />
        </div>
    });
  }

  render () {
    return (
      <div className="Shortlist">
        <div id="shortlist-preview">
          {this.renderShortlistPreview()}
        </div>
      </div>
    );
  }

}


