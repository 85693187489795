import React from 'react';
import * as Router from "react-router-dom";
import './App.scss';
import Button from 'react-bootstrap/Button';
import * as clipboard from './logic/clipboard';
import { getDB, officialList, Track } from './logic/core';
import LonglistLogic from './logic/longlist';
import Spotify from './logic/spotify';
import EmailButton from './EmailButton';

const COPY_ICON = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z"/>
  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
  <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
</svg>;

const PASTE_ICON = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
  <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
</svg>;

const RESET_ICON = <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
  <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zm-6.106 4.5L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z"/>
</svg>


interface AppProps {}

interface AppState {
  loggedInToSpotify: boolean
}

class App extends React.Component<AppProps, AppState> {

  handlePasteEvent: (event: ClipboardEvent) => Promise<void>;

  constructor(props: AppProps) {
    super(props);
    this.state = {
      loggedInToSpotify: Spotify.isLoggedIn
    };

    this.handlePasteEvent = async (event: ClipboardEvent) => {
      const dataText = event.clipboardData?.getData('text/plain');
      if (dataText) {
        await this.handleClipboardData(dataText);
      }
    };
  }

  componentDidMount() {
    document.addEventListener('paste', this.handlePasteEvent);
  }

  componentWillUnmount() {
    document.removeEventListener('paste', this.handlePasteEvent);
  }

  copyState() {
    clipboard.writeText(async () => {
      const db = await getDB();
      const longlist = await db.getAll('longlist');
      const matchupResults = await db.getAll('matchupResults');

      let spotify = Spotify.copyData();

      return JSON.stringify({longlist, spotify, matchupResults});
    });
  }

  pasteState() {
    this.loadFromClipboard().catch((e) => {
      alert(e.toString());
    });
  }

  async loadFromClipboard() {
    try {
      const dataText = await clipboard.readText();
      await this.handleClipboardData(dataText);
    } catch (e) {
      if ((e instanceof DOMException) && (e.name === 'NotAllowedError')) {
        // do nothing, we don't care
      } else {
        throw e;
      }
    }
  }

  async handleClipboardData(dataText: string) {
    // Is this a spotify URL?
    try {
      let url = new URL(dataText);
      if (url.host === 'open.spotify.com') {
        // this is a spotify URL
        await this.loadFromSpotify(url);
        return;
      }
    } catch (e) {
      if (e instanceof TypeError) {
        // do nothing just continue
      } else {
        throw e;
      }
    }

    // First, try to parse as JSON
    try {
      const data = JSON.parse(dataText);
      await this.loadJSONData(data);
    } catch {
      // No luck? try plain text copied from JJJ voting site
      this.loadVotingPlainText(dataText);
    }

  }

  async loadFromSpotify(url: URL) {
    // https://open.spotify.com/playlist/<PLAYLIST_ID>
    const confirmed = window.confirm("Replace your shortlist with the tracks in this Spotify playlist?");

    if (!confirmed) {
      return;
    }

    const pathComponents = url.pathname.split('/').filter(s => s.length > 0);
    if (pathComponents[0] === 'playlist') {
      const playlistId = pathComponents[1];
      await Spotify.performAction("replaceShortlistWithPlaylist", playlistId);
    }

    document.dispatchEvent(new CustomEvent('app:state-changed'));
  }

  async loadJSONData(data: Record<string, any>) {
    const newLonglist = data.longlist;

    if (newLonglist == null) {
      throw new Error('Failed to load from clipboard');
    }

    const confirmed = window.confirm("Replace all your progress with the data on the clipboard?");

    if (confirmed) {
      const db = await getDB();
      const longlist = await db.transaction('longlist', 'readwrite').store;
      await longlist.clear();

      for(let track of newLonglist) {
        await longlist.put(track);
      }

      if (data.matchupResults) {
        const matchupResults = await db.transaction('matchupResults', 'readwrite').store;
        await matchupResults.clear();

        for(let result of data.matchupResults) {
          await matchupResults.put(result);
        }
      }

      const spotify = data.spotify;
      Spotify.pasteData(spotify);

      document.dispatchEvent(new CustomEvent('app:state-changed'));
    }
  }

  async loadVotingPlainText(text: string) {
    const startListRegex = /^Your Shortlist$/m;

    let startPos = text.search(startListRegex);
    if (startPos === -1) {
      startPos = 0;
    }

    const searchSpace = text.substring(startPos);

    const trackRegex = /^Artist:(.*?)Title:(.*?)$/gms;

    function makeMatcher(artist: string, title: string): string {
      // We can't trust the case of these across browsers, so case match them
      return `${artist.trim().toUpperCase()}\n${title.trim().toLowerCase()}`
    }

    const trackMatchers = new Set<string>();
    for (let match of searchSpace.matchAll(trackRegex)) {
      trackMatchers.add(makeMatcher(match[1], match[2]));
    }

    if (trackMatchers.size === 0) {
      // Do nothing
      alert('Copy and paste your shortlist from the JJJ voting page or from the Hottest 100 Helper.')
      return;
    }

    const shortlist = new Set<Track>();
    const missingTracks = new Set(trackMatchers);
    for(let track of Object.values(officialList)) {
      const matcher = makeMatcher(track.artist_name, track.title);
      if (trackMatchers.has(matcher)) {
        shortlist.add(track);
        missingTracks.delete(matcher);
      }
    }

    let missingMessage = '';
    if (missingTracks.size > 0) {
      let trackStrings = Array.from(missingTracks).map(o => o.replace('\n', ' – '));
      missingMessage = `\nCouldn't find matches for:\n${trackStrings.join('\n')}`
    }

    const confirmed = window.confirm(`This will replace your shortlist with ${shortlist.size} tracks from the Hottest 100 voting page.${missingMessage}`);

    if (confirmed) {
      const longlistLogic = new LonglistLogic();
      await longlistLogic.resetLonglist("reject");

      const db = await getDB();
      const longlist = await db.transaction('longlist', 'readwrite').store;

      for(let track of shortlist) {
        let record = await longlist.get(track.id);
        if (record) {
          record.state = "accept";
          await longlist.put(record);
        }
      }

      document.dispatchEvent(new CustomEvent('app:state-changed'));
    }
  }

  async resetState() {
    if (window.confirm("Are you sure you want to completely reset your progress? If you've just made one mistake, you can use the undo button.")) {
      const longlistLogic = new LonglistLogic();
      await longlistLogic.resetLonglist();
      document.dispatchEvent(new CustomEvent('app:state-changed'));
    }
  }

  renderLogOutOfSpotify() {
    if (this.state.loggedInToSpotify) {
      return (
        <p className="text-center">
          <Button variant="dark" className="svg-button" size="sm" onClick={() => this.logOutOfSpotify()}>
             Log out of Spotify
          </Button>
        </p>
      );
    }
  }

  logOutOfSpotify() {
    Spotify.clearToken();
    this.setState({
      loggedInToSpotify: false
    });
  }

  render () {
    return (
      <div className="App pb-4">
        <h1 className="main-title"><a href="/">Hottest 100 Helper 2023</a></h1>
        <div className="d-flex text-center mb-4">
          <Router.NavLink to="/longlist"
          className={({ isActive }) => isActive ? "nav-link nav-link-active" : "nav-link"}>Create shortlist</Router.NavLink>
          <span className="h2 separator">&rsaquo;</span>
          <Router.NavLink to="/shortlist"
          className={({ isActive }) => isActive ? "nav-link nav-link-active" : "nav-link"}>Edit shortlist</Router.NavLink>
          <span className="h2 separator">&rsaquo;</span>
          <Router.NavLink to="/matchup"
          className={({ isActive }) => isActive ? "nav-link nav-link-active" : "nav-link"}>Pick your top 10</Router.NavLink>
        </div>

        <Router.Outlet />

        <hr />

        <div className="text-center">
          <Button variant="primary" className="svg-button" onClick={() => this.copyState()}>
            {COPY_ICON} Copy
          </Button>
&nbsp;
          <Button variant="primary" className="svg-button" onClick={() => this.pasteState()}>
            {PASTE_ICON} Paste
          </Button>

          <p className="mt-2">
            You can paste from another Hottest 100 Helper, from the Hottest 100 voting site, or a Spotify playlist URL. When you paste, you'll completely overwrite your shortlist, but we'll keep the results from any match-ups you've already chosen.
          </p>

          <p>Feedback? Bug reports? <EmailButton value="8PsWPAyA6RnZf2/rnZc4E++BeDdhZKhtsKa9jYg= nZRkW23uwnG2CxuO7uMJI9/BH1gTCdsZ1dST4O0=">Email me</EmailButton></p>

          <p><Router.NavLink to="/privacy-policy" className="btn btn-link">Privacy policy</Router.NavLink></p>
        </div>

  <p className="text-center">
    <Button variant="dark" className="svg-button" size="sm" onClick={() => this.resetState()}>
       {RESET_ICON} Reset
    </Button>
  </p>

  {this.renderLogOutOfSpotify()}

      </div>
    );
  }
}

export default App;
