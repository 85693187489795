import React from 'react';
import { Navigate, Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spotify from './logic/spotify';

interface SpotifyRedirectProps {}

interface SpotifyRedirectState {
  redirect?: string
  error?: string
}

export default class SpotifyRedirect extends React.Component<SpotifyRedirectProps, SpotifyRedirectState> {

  result?: URLSearchParams

  constructor(props: SpotifyRedirectProps) {
    super(props);
    const windowHash = window.location.hash;
    if (windowHash.length < 2) {
      this.state = {
        redirect: '/'
      };
      return;
    }

    this.state = {};

    this.result = new URLSearchParams(windowHash.substring(1));
  }

  componentDidMount() {
    if (this.result) {
      Spotify.handleRedirect(this.result).then(redirect => {
        this.setState({redirect: redirect ?? '/'});
      }, error => {
        this.setState({
          redirect: '/',
          error: error.toString()
        });
      });
    }
  }

  render() {
    if (this.state.redirect) {
      if (this.state.error) {
        return <div className="text-center">
          <Alert variant="warning">Spotify login returned an error: {this.state.error}</Alert>
          <Link to={this.state.redirect ?? '/'}><Button>Continue</Button></Link>
        </div>
      } else {
        return <Navigate to={this.state.redirect} />
      }
    } else {
      return <div className="h1 text-center">Loading from Spotify...</div>
    }
  }

}
