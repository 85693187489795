export default class AudioPlayer extends EventTarget {

  private _audio = new Audio();

  private constructor() {
    super();
    this._audio.addEventListener('ended', (evt) => {
      this.dispatchEvent(new CustomEvent('ended', {detail: {src: this._audio.src}}));
    });
  }

  public static shared = new AudioPlayer();

  public play(src: string) {
    if (this._audio.src !== src) {
      // Changing source, broadcast ended
      this.dispatchEvent(new CustomEvent('ended', {detail: {src: this._audio.src}}));
      this._audio.src = src;
    }
    if (this._audio.paused) {
      this._audio.play();
    }
  }

  public pause() {
    this._audio.pause();
  }

}
