import React from 'react';
import * as Router from "react-router-dom";
import './Shortlist.scss';
import TrackDisplay from './TrackDisplay';
import Logic from './logic/longlist';
import MatchupLogic, { WeightedScores } from './logic/matchup';
import { Track, spotifyArt, officialList, spotifyURL, previewURL } from './logic/core';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spotify from './logic/spotify';

interface ShortlistProps {}

interface ShortlistState {
  currentShortlist: Track[],
  playlistText: string,
  debugScores: Record<number, WeightedScores>
}

const _DEBUG_SORT_BY_SCORE = true;

class Shortlist extends React.Component<ShortlistProps, ShortlistState> {

  private logic = new Logic();
  private matchupLogic = new MatchupLogic();

  private handleAppStateChanged: () => Promise<void>;

  constructor(props: ShortlistProps) {
    super(props);
    this.state = {
      currentShortlist: [],
      playlistText: Spotify.isPlaylistCreated ? 'Update Spotify playlist' : 'Create Spotify playlist',
      debugScores: {}
    };

    this.handleAppStateChanged = async () => {
      await this.updateShortlist();
    }
  }

  async componentDidMount() {
    await this.updateShortlist();
    document.addEventListener('app:state-changed', this.handleAppStateChanged);
  }

  componentWillUnmount() {
    document.removeEventListener('app:state-changed', this.handleAppStateChanged);
  }

  async updateShortlist() {
    const shortlist = await this.logic.getShortlist();
    const scores: Record<number, WeightedScores> = {};

    if (_DEBUG_SORT_BY_SCORE) {
      for(let track of shortlist) {
        const weighted = await this.matchupLogic.getWeightedScore(track.id);
        scores[track.id] = weighted;
      }

      shortlist.sort((a, b) => {
        let aWeight = scores[a.id].winWeight - scores[a.id].lossWeight;
        let bWeight = scores[b.id].winWeight - scores[b.id].lossWeight;
        return bWeight - aWeight;
      });
    }

    this.setState({currentShortlist: shortlist, debugScores: scores});
  }

  async deleteTrack(track: Track) {
    await this.logic.updateState(track, 'reject');
    await this.updateShortlist();
  }

  renderShortlistPreview() {
    return this.state.currentShortlist.map((track) => {
      return <div key={track.id}><TrackDisplay
        track={track.title}
        artist={track.artist_name}
        albumArt={spotifyArt[track.id]?.["300"]}
        playbackUrl={previewURL(track)}
        spotifyUrl={spotifyURL(track)}
        onDelete={() => this.deleteTrack(track)}
        />
        {_DEBUG_SORT_BY_SCORE &&
          <pre>{this.state.debugScores[track.id].wins}
          ({this.state.debugScores[track.id].winWeight.toFixed(2)})
           / {this.state.debugScores[track.id].losses}
           ({this.state.debugScores[track.id].lossWeight.toFixed(2)})
           </pre>
         }
        </div>
    });
  }

  renderShortlistDatalist() {
    let elements = Object.values(officialList).map((track) => (
      <option key={track.id} data-track-id={track.id} value={`${track.artist_name} – ${track.title}`} />
    ));

    return (<datalist id="datalist-shortlist">
      {elements}
      </datalist>);
  }

  async handleAddSongChange(evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = evt.currentTarget.value;
    const datalist = document.getElementById('datalist-shortlist');
    const option = datalist?.querySelector(`[value="${value}"]`) as HTMLOptionElement;
    if (!option) {
      return;
    }

    const trackId = option.dataset.trackId;
    if (!trackId) {
      return;
    }

    const track = officialList[trackId];
    await this.logic.updateState(track, "accept");
    await this.updateShortlist();

    evt.currentTarget.value = '';
  }

  async updateSpotifyPlaylist() {
    await Spotify.performAction("updatePlaylistFromShortlist");
    this.setState({playlistText: 'Update Spotify playlist'});
  }

  render () {
    return (
      <div className="Shortlist">
        <Form.Control type="text" autoCorrect="off" placeholder="Add a song" size="lg" className="input-max-width" list="datalist-shortlist" onChange={(evt) => this.handleAddSongChange(evt)} />
        <div className="text-center mt-2">
          <Button variant="outline-fg" onClick={() => this.updateSpotifyPlaylist()}>{this.state.playlistText}</Button>
        </div>
        <div className="text-center">
          {this.state.currentShortlist.length} songs
        </div>
        {this.renderShortlistDatalist()}
        <div id="shortlist-preview">
          {this.renderShortlistPreview()}
        </div>
      </div>
    );
  }

}

export default Shortlist;
