export async function writeText(value: () => Promise<string>): Promise<void> {
  try {
    const promise = async () => {
      const v = await value();
      return new Blob([v], {type: 'text/plain'});
    }

    const clipboardItem = new ClipboardItem({
      'text/plain': Promise.resolve(promise())
    });

    await navigator.clipboard.write([clipboardItem]);
  } catch {
    const v = await value();
    await navigator.clipboard.writeText(v);
  }
}

export async function readText(): Promise<string> {
  if (navigator.clipboard.read) {
    const items = await navigator.clipboard.read();
    for (let item of items) {
      if (item.types.indexOf('text/plain') !== -1) {
        const blob = await item.getType('text/plain');
        return await blob.text();
      }
    }
    throw new Error('Unknown type pasted');
  } else {
    return await navigator.clipboard.readText();
  }
}

